<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">数据统计</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">机构统计</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl">
          <div class="searchbox">
            <div title="机构名称" class="searchboxItem ci-full">
              <span class="itemLabel">机构名称:</span>
              <el-input v-model="agencyName" type="text" size="small" clearable placeholder="请输入机构名称" />
            </div>
            <div title="行政区划" class="searchboxItem ci-full">
              <span class="itemLabel">行政区划:</span>
              <el-cascader
                v-model="areaId"
                :options="areatreeList"
                :props="propsarea"
                :disabled="disabled"
                clearable
                size="small"
                 @change="(val)=>{clean(val,'areaId')}"
              ></el-cascader>
            </div>
            <div title="培训时间" class="searchboxItem ci-full">
              <span class="itemLabel">培训时间:</span>
             <el-date-picker
                clearable
                size="small"
                v-model="trainTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                 format="yyyy-MM-dd"
                      value-format="yyyy-MM-dd"
              ></el-date-picker>
            </div>
            <el-button class="bgc-bv" round @click="getData()">查询</el-button>
             <el-button class="bgc-bv" round @click="handleExpotrt()">导出</el-button>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
            label="序号"
            align="center"
            type="index"
            width="50px"
            :index="indexMethod"
          />
              <el-table-column label="行政区划" align="center" prop="areaName" show-overflow-tooltip />
              <el-table-column label="机构名称" align="center" prop="agencyName" />
              <el-table-column label="培训学员总数" align="center" prop="totalPeopleNum" show-overflow-tooltip />
              <el-table-column label="已学习完成学员总数" align="center" prop="learnFinishedPeopleNum" show-overflow-tooltip />
              <el-table-column label="取得合格证人数" align="center" prop="qualifiedPeopleNum" show-overflow-tooltip />
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "operate/roleList",
  components: {
    Empty,
    PageNum
  },
  mixins: [List],
  data() {
    return {
      agencyName: "", //机构名称（代理商）
      areaId:"", //行政区划
      trainTime:'', //培训时间
      areatreeList:[],
       propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true
      },
       search: {
        areaId: true,
      }
    };
  },
  computed: {},
   mounted() {
    this.getareatree();
  },
  methods: {
  
    getData(pageNum = 1) {
       if (this.search.areaId) {
        this.areaId = this.$route.query.areaId;
      }
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize
      };
      if (this.agencyName) {
        params.agencyName = this.agencyName;
      }
      if (this.areaId) {
        params.areaId = this.areaId;
      }
      if (this.trainTime) {
        params.trainDateStart = this.trainTime[0];
        params.trainDateEnd = this.trainTime[1];
      }
      this.doFetch({
        url: "/agency/agency-gov-static/agencyList",
        params,
        pageNum
      });
    },
    clean(val, name) {
      this.areaId = val;
      this.search.areaId = false;
    },
     // 导出
    handleExpotrt() {
        this.$confirm('导出数据仅限于业务范围内使用，不得用于其它用途，如因个人原因导致数据泄露，由个人承担法律责任。', '导出提示', {
          confirmButtonText: '导出',
           confirmButtonClass: "confirmButtonClass",
          cancelButtonText: '取消',
        }).then(() => {
             const params = {
      };
      if (this.agencyName) {
        params.agencyName = this.agencyName;
      }
      if (this.areaId) {
        params.areaId = this.areaId;
      }
      if (this.trainTime) {
        params.trainDateStart = this.trainTime[0];
        params.trainDateEnd = this.trainTime[1];
      }
          this.$post("/agency/agency-gov-static/agencyListExport", params).then((res) => {
            if (res.status == "0") {
              window.open(res.data);
            }
          });
        }).catch(() => {         
        });
      
    },
     //获取行政区划
    getareatree() {
      this.$post("/gov/area/tree").then(ret => {
        this.areatreeList = ret.data;
      });
    },
     getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    $route: function(val) {
      if (JSON.stringify(val.query) != "{}") {
        this.search.areaId = true;
        this.areaId = "";
        this.getData(-1);
      }
    }
  }
};
</script>